import { AfterViewInit, Component, ElementRef, EventEmitter, Input, ViewChild, ViewChildren } from '@angular/core';

import { Subscription } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';

import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { ResizeResult } from 'ngxtension/resize';
import { ToastrService } from 'ngx-toastr';
import { TourService } from 'ngx-ui-tour-md-menu';

import _ from 'lodash';

import { TacticalDashboardOverviewGaugeComponent } from '../../components/tactical-dashboard-overview-gauge/tactical-dashboard-overview-gauge.component';
import { TacticalWidgetSummaryTilePopupComponent } from '../../components/tactical-widget-summary-tile-popup/tactical-widget-summary-tile-popup.component';
import { v4 as uuidv4 } from 'uuid';

import { Global } from '../../_constants/global.variables';

import { IGlobal } from '../../_models/global.model';

import { DashboardService } from '../../services/dashboard.service';
import { DataService } from '../../services/data.service';
import { UtilityService } from '../../services/utility.service';

import { DialogModalParentComponent } from '../dialog-modal-parent/dialog-modal-parent.component';

@Component({
	selector: 'lib-pbb-docking',
	templateUrl: './pbb-docking.component.html',
	styleUrls: ['./pbb-docking.component.scss'],
})
export class PbbDockingComponent implements AfterViewInit {
	@ViewChildren('seriesItems')
	public series: any;

	@ViewChild('chart')
	public chart: any;
	public graphTypes: string[] = ['area', 'bar', 'column', 'donut', 'horizontalWaterfall', 'line', 'verticalArea', 'verticalLine', 'waterfall'];

	@ViewChild('childOverviewGauge')
	childOverviewGauge: TacticalDashboardOverviewGaugeComponent;

	@Input() widgetObject: any;
	@Input() private dashboardTimeScopeChanged: EventEmitter<any>;
	@Input() private dashboardUpdateIntervalChanged: EventEmitter<any>;
	@Input() private widgetResizedEvent: EventEmitter<any>;
	public global: IGlobal = Global;

	fullDataCacheSubscription: any;
	colorChangedSubscription: any;
	dashboardUpdateIntervalSubscription: any;
	dashboardTimeScopeChangedSubscription: any;
	selectedMatTabLabel: string;
	updateInterval: any;
	displayNoTimeScopeMessage: boolean = false;
	displayNoUpdateIntervalMessage: boolean = false;
	secondsAgoInterval: any;
	lastUpdatedDate: Date;
	displayTimeAgoMessage: any;
	updateControllerInterval: any;
	parentContainerSize: any;
	widgetTabsChartConfigurations: any;
	theme: string;
	chartBackgroundColor: string = '#27304C';
	chartLabelColors: string = 'white';
	gridLineColor: string = 'white';
	axisItemColor: string = 'white';
	h1Number: number = undefined;
	h4Percent: number = undefined;
	fontSize1 = 'medium';
	fontSize2 = 'small';
	// cardClass1 = 'card-tile-lg';
	// cardClass2 = 'card-tile-md';
	// cardWideClass2 = 'card-wide-tile-lg';
	cardTileCenterTitleClass = 'grid-card-title-center-sm';
	cardWideTileCenterTitleClass = 'grid-wide-card-title-center-sm';
	headerGridTitle = 'grid-title-lg';
	contentHeight: any;
	totalAssetLength: any;
	totalDocksForToday: any;
	percentCalculated: boolean = false;
	gaugeDisplayData: any;
	chartDataFinalIsReady: boolean = false;
	widgetTabConfigurationsFinishedMapping: boolean = false;
	summaryArray = [];
	summaryArray1 = [];
	summaryArray2 = [];
	sparklineData = [];
	widgetResizedSubscription: any;
	naviageToFirstTab: any;
	public label = {
		visible: false,
	};
	public progressStyles: { [key: string]: any } = {
		background: 'limegreen',
	};
	isLoading: boolean;

	public timeScopeObject: any;
	public tab: any;
	public options: any = [];
	public optionsSettingsOnly: any = [];
	public navigationOpened: any;

	public widgetTabs: any = [
		{
			isLoading: false,
			tabHasBeenLoaded: false,
			textLabel: 'Summary',
			tooltip:
				'Summary showing within the current scope settings (Site) the total amount of assets that are currently docked.Click on the info to navigate to corresponding tab for details.',
		},
		{
			isLoading: false,
			tabHasBeenLoaded: false,
			textLabel: 'Current Docked Assets',
			graphType: this.graphTypes[3],
			tooltip:
				'Donut chart showing within the current scope settings (Site, asset type) the amount of assets currently docked with a tooltip that shows which assets are in each category.',
			graphTypesLimitations: [2, 3],
			downloadableData: true,
			chartDataIsReady: false,
			legendVisible: true,
			groupable: true,
			groupColumns: [{ field: 'IsAircraftDocked' }],
			tableStructure: [
				{
					field: 'DateOfObservationDateObject',
					title: 'Date',
					filter: 'date',
					_width: 300,
					formatOption: 'shortDate',
				},
				{
					field: 'SiteName',
					title: 'Site',
					_width: 100,
				},

				{
					field: 'GateName',
					title: 'Gate Name',
					_width: 300,
				},
				{
					field: 'ProperAssetName',
					title: 'Asset Name',
					_width: 300,
				},
				{
					field: 'IsAircraftDocked',
					title: 'Aircraft Docked Status',
					_width: 300,
				},
			],
		},
		{
			isLoading: false,
			tabHasBeenLoaded: false,
			textLabel: 'Assets Docked Current Time',
			tooltip:
				'Detailed chart showing within the current scope settings (Site) the amount of Assets docked at the time this report was generated over the last amount of days selected on timescope. ',
			graphTypesLimitations: [],
			graphType: this.graphTypes[5],
			downloadableData: true,
			chartDataIsReady: false,
			groupable: true,
			groupColumns: [{ field: 'ProperAssetName' }],

			tableStructure: [
				{
					field: 'ObservationDateTime',
					title: 'Date',
					filter: 'date',
					_width: 300,
					// formatOption: 'shortDate'
				},
				{
					field: 'SiteName',
					title: 'Site',
					_width: 100,
				},

				{
					field: 'GateName',
					title: 'Gate Name',
					_width: 300,
				},
				{
					field: 'ProperAssetName',
					title: 'Asset Name',
					_width: 300,
				},
				{
					field: 'ObservationTextValue',
					title: 'Docked',
					_width: 300,
				},
			],
		},
		{
			isLoading: false,
			tabHasBeenLoaded: false,
			textLabel: 'Total Minutes Docked For Day',
			rendered: false,
			graphType: this.graphTypes[5],
			tooltip:
				'Detailed line chart showing within the current scope settings (Site) the total amount of time Assets were docked for each day of the trend along with the amount of times they were docked.',
			legendVisible: true,
			downloadableData: true,
			groupable: true,
			groupColumns: [{ field: 'ProperAssetName' }],

			tableStructure: [
				{
					field: 'DateOfObservationDateObject',
					title: 'Date',
					filter: 'date',
					_width: 300,
					formatOption: 'shortDate',
				},
				{
					field: 'SiteName',
					title: 'Site',
					_width: 100,
				},

				{
					field: 'GateName',
					title: 'Gate Name',
					_width: 300,
				},
				{
					field: 'ProperAssetName',
					title: 'Asset Name',
					_width: 300,
				},
				{
					field: 'AircraftDockedMinutes',
					title: 'Minutes Docked in Day',
					_width: 300,
				},
			],
		},
		{
			isLoading: false,
			tabHasBeenLoaded: false,
			textLabel: 'Total Times Docked For Day',
			rendered: false,
			graphType: this.graphTypes[5],
			tooltip:
				'Detailed line chart showing within the current scope settings (Site) the total amount of times Assets were docked for each day of the trend along with the amount of minutes they were docked.',
			legendVisible: true,
			downloadableData: true,
			groupable: true,
			groupColumns: [{ field: 'ProperAssetName' }],

			tableStructure: [
				{
					field: 'DateOfObservationDateObject',
					title: 'Date',
					filter: 'date',
					_width: 300,
					formatOption: 'shortDate',
				},
				{
					field: 'SiteName',
					title: 'Site',
					_width: 100,
				},

				{
					field: 'GateName',
					title: 'Gate Name',
					_width: 300,
				},
				{
					field: 'ProperAssetName',
					title: 'Asset Name',
					_width: 300,
				},
				{
					field: 'AircraftDockedCount',
					title: 'Count of Aircraft Docked in Day',
					_width: 300,
				},
			],
		},
		{
			isLoading: false,
			tabHasBeenLoaded: false,
			textLabel: 'Docks For Today Detail',
			graphType: this.graphTypes[2],
			tooltip:
				'Detailed chart showing docks for today',
			legendVisible: true,
			downloadableData: true,
			groupable: true,
			groupColumns: [],
			tableStructure: [
				{
					field: 'SiteName',
					title: 'Site',
					_width: 100,
				},

				{
					field: 'ProperAssetName',
					title: 'Asset Name',
					_width: 300,
				},
				{
					field: 'DurationInSeconds',
					title: 'Seconds Docked',
					_width: 100,
				},
				{
					field: 'DurationInMinutes',
					title: 'Minutes Docked',
					_width: 100,
				},
				{
					field: 'StartDate',
					title: 'Start Date',
					filter: 'date',
					_width: 300,
				},
				{
					field: 'EndDate',
					title: 'End Date',
					filter: 'date',
					_width: 300,
				},
			],
		},
	];
	noDataForSite: boolean = false;
	timeoutToExpire: any;
	selectedTabIndex: number = 0;
	WidgetTabSettings: any = [];
	widgetNavigateToFirstTabSubscription: Subscription;
	widgetToggleLegendHiddenSubscription: Subscription;

	ConditionalShadingRecord: any;
	percentTextColor: any = [];
	conditionalColorPercentAmount: any = [];
	holeSize = 30;
	holeCenterTextClass = 'donut-center-text-md';
	donutClass = 'donut-md';
	public percentAssetsInUse = [];
	public percentAssetsNotInUse = [];

	constructor(
		public dataService: DataService,
		private dashboardService: DashboardService,
		private dialog: MatDialog,
		public toastr: ToastrService,
		private utilityService: UtilityService,
		private elem: ElementRef,
		public tourService: TourService,
	) {}
	public labelContent(e: any): string {
		return e.category + ': ' + e.value;
	}
	public labelContentShort(e: any): string {
		return e.value;
	}

	ngOnInit() {
		this.tab = this.widgetTabs[0];
		this.updateInterval =
			this.dashboardService.determineProperTacticalDashboardUpdateIntervalForWidget(
				this.widgetObject
			);
		this.timeScopeObject =
		this.dashboardService.determineProperTimeScopeForWidget({
			widgetObject: this.widgetObject,
			UTCConfiguration: false,
		});
		this.buildNavigationOptionsArray();
	}

	ngAfterViewInit() {


		var bounds = this.elem.nativeElement.getBoundingClientRect();
		this.cardResize(bounds);

		if(Global.isMobile == true) {
			this.contentHeight = 'calc(100% - 20px)';
		}
		else {
			this.contentHeight = 'calc(100% - 120px)';
		}

		let statement =
			'API.GetWidgetTabSettings @WidgetId = ' +
			this.widgetObject.WidgetId;
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			this.WidgetTabSettings = data;
			this.WidgetTabSettings.forEach((tabSetting) => {
				this.widgetTabs[tabSetting.TabIndex].legendVisible =
					!tabSetting.IsLegendHidden;
			});
			console.log(this);
		});

		this.ConditionalShadingRecord = this.dataService.cache.tacticalDashboardWidgetConditionalShadings.filter((p: any) => {
			return p.WidgetId == this.widgetObject.WidgetId;
		});

		if (this.ConditionalShadingRecord.length > 0) {
			this.ConditionalShadingRecord.sort((a, b) => parseFloat(a.Value) - parseFloat(b.Value));
		}

		if (this.widgetResizedEvent) {
			this.widgetResizedSubscription = this.widgetResizedEvent.subscribe((data) => {
				if (this.widgetObject.WidgetId == data.item.WidgetId) {
					this.cardResize(data);
					this.fontResize(data);
				}
			});
		}
		this.widgetNavigateToFirstTabSubscription = this.dashboardService._widgetNavigateToFirstTab.subscribe((widgetObject: any) => {
			if (widgetObject && parseInt(this.widgetObject.WidgetId) === parseInt(widgetObject.WidgetId)) {
				this.selectedTabIndex = 0
			}
		});

		this.widgetToggleLegendHiddenSubscription = this.dashboardService._widgetToggleLegendHidden.subscribe((widgetObject: any) => {
			if (widgetObject && parseInt(this.widgetObject.WidgetId) === parseInt(widgetObject.WidgetId)) {
				this.toggleLegendHidden(this.tab ,this.selectedTabIndex);
			}
		});

		this.isLoading = true;
		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription =
				this.dataService.fullDataCacheExists$.subscribe((data: any) => {
					if (data === true) {
						this.initialize();
						this.fullDataCacheSubscription.unsubscribe();
					}
				});
		} else {
			this.initialize();
		}

		this.colorChangedSubscription =
			this.dataService.colorChanged$.subscribe((data: any) => {
				if (data === 'light') {
					this.CreateTheme('light');
				} else {
					this.CreateTheme('dark');
				}
			});
		if (Global.Theme === 'light') {
			this.CreateTheme('light');
		} else {
			this.CreateTheme('dark');
		}

		if (this.dashboardUpdateIntervalChanged) {
			this.dashboardUpdateIntervalSubscription =
				this.dashboardUpdateIntervalChanged.subscribe((data) => {
					console.log(data);
					let foundWidgetWithSameWidgetId = data.find(
						(widgetThatWasChanged) => {
							return (
								widgetThatWasChanged.WidgetId ===
								this.widgetObject.WidgetId
							);
						}
					);

					if (!_.isNil(foundWidgetWithSameWidgetId)) {
						//if data (which is list of widgets that had the time zone changed as an array of widgets includes a widget with this widget id, we can assume this widget needs to be updated. needs to initialize with new selected time zone in mind. )
						this.initialize();
					}
				});
		}
		if (this.dashboardTimeScopeChanged) {
			this.dashboardTimeScopeChangedSubscription =
				this.dashboardTimeScopeChanged.subscribe((data) => {
					let foundWidgetWithSameWidgetId = data.find(
						(widgetThatWasChanged) => {
							return (
								widgetThatWasChanged.WidgetId ===
								this.widgetObject.WidgetId
							);
						}
					);

					if (!_.isNil(foundWidgetWithSameWidgetId)) {
						console.log('Widget Time Zone Changed');
						this.initialize();
					}
				});
		}
		// this.chartDataIsReady = true;
		this.tab = this.widgetTabs[0];
	}



	openConditionalShadingSettingsPopup(tileNumber) {
		let tileConditionalShadingRecord =
			this.dataService.cache.tacticalDashboardWidgetConditionalShadings.filter((p: any) => {
				return p.WidgetId == this.widgetObject.WidgetId && p.SummaryTileIndex == tileNumber;
			});
		if(tileConditionalShadingRecord.length == 0) {
			tileConditionalShadingRecord.WidgetId = this.widgetObject.WidgetId;
			tileConditionalShadingRecord.SummaryTileIndex = tileNumber;
		}

		var DialogRef = this.dialog.open(TacticalWidgetSummaryTilePopupComponent, {
			width: '50%',
			height: '40%',
			data: tileConditionalShadingRecord,
		});


		DialogRef.afterClosed().subscribe((result) => {
			let tileConditionalShadingRecordLatest =
				this.dataService.cache.tacticalDashboardWidgetConditionalShadings.filter((p: any) => {
					return p.WidgetId == this.widgetObject.WidgetId;
				});
			this.ConditionalShadingRecord = [];
			tileConditionalShadingRecordLatest.forEach((r) => {
				this.ConditionalShadingRecord.push(r);
			});

			this.ConditionalShadingRecord.sort((a, b) => parseFloat(a.Value) - parseFloat(b.Value));
			this.ConditionalColorSummaryTiles();

		});

	}

	private ConditionalColorSummaryTiles() {
		this.percentTextColor[1] = this.percentTextColor[2] = "lightseagreen";

		this.conditionalColorPercentAmount[1] = this.summaryArray[2];
		this.conditionalColorPercentAmount[2] = this.summaryArray[3];


		if (this.ConditionalShadingRecord.length > 0) {
			this.ConditionalShadingRecord.forEach((r) => {

				switch (r.Operator) {
					case 'Is greater than' :
						if(this.conditionalColorPercentAmount[r.SummaryTileIndex] > r.Value) {
							this.percentTextColor[r.SummaryTileIndex] = r.HexColor;
						}
						break;
					case 'Is less than' :
						if(this.conditionalColorPercentAmount[r.SummaryTileIndex] < r.Value) {
							this.percentTextColor[r.SummaryTileIndex] = r.HexColor;
						}
						break;
					case 'Is equal to' :
						var conditionalColorPercentAmountRounded = Math.round(this.conditionalColorPercentAmount[r.SummaryTileIndex]);
						if(conditionalColorPercentAmountRounded == r.Value) {
							this.percentTextColor[r.SummaryTileIndex] = r.HexColor;
						}
						break;
					case 'Is not equal to' :
						var conditionalColorPercentAmountRounded = Math.round(this.conditionalColorPercentAmount[r.SummaryTileIndex]);
						if(conditionalColorPercentAmountRounded != r.Value) {
							this.percentTextColor[r.SummaryTileIndex] = r.HexColor;
						}
						break;
				}
			});
		}
		this.percentAssetsInUse = [
			{
				kind: "assets in use",
				share: this.summaryArray[2] / 100,
				color: this.percentTextColor[1],
			},
			{
				kind: "other",
				share: 1 - this.summaryArray[2] / 100,
				color: "grey",
			},
		];


		this.percentAssetsNotInUse = [
			{
				kind: "assets in use",
				share: this.summaryArray[3] / 100,
				color: this.percentTextColor[2],
			},
			{
				kind: "other",
				share: 1 - this.summaryArray[3] / 100,
				color: "grey",
			},
		];

	}


	fontResize(data: any) {

		if (data.height < 400 || data.width < 400) {
			this.fontSize1 = 'small';
			this.fontSize2 = 'x-small';
		}

		else if ((data.height > 400 && data.height < 500) || (data.width > 400 && data.width < 600)) {
			this.fontSize1 = 'medium';
			this.fontSize2 = 'small';
		}

		else if ((data.height > 500 && data.height < 700) || (data.width > 600 && data.width < 800)) {
			this.fontSize1 = 'large';
			this.fontSize2 = 'medium';
		}
		else if (data.height > 700 || data.width > 800) {
			this.fontSize1 = 'x-large';
			this.fontSize2 = 'large';
	  }
  }



	cardResize(data: any) {

		if (data.height < 400 || data.width < 400) {
			// this.cardClass2 = 'card-tile-xs';
			// this.cardWideClass2 = 'card-wide-tile-sm';

			this.cardTileCenterTitleClass = 'grid-card-title-center-sm';
			this.cardWideTileCenterTitleClass = 'grid-wide-card-title-center-sm';

			this.holeSize = 55;
			this.holeCenterTextClass = 'donut-center-text-xs';
			this.donutClass = 'donut-sm';

		}

		else if ((data.height > 400 && data.height < 500) || (data.width > 400 && data.width < 600)) {
			// this.cardClass2 = 'card-tile-sm';
			// this.cardWideClass2 = 'card-wide-tile-md';

			this.cardTileCenterTitleClass = 'grid-card-title-center-md';
			this.cardWideTileCenterTitleClass = 'grid-wide-card-title-center-md';

			this.holeSize = 60;
			this.holeCenterTextClass = 'donut-center-text-sm';
			this.donutClass = 'donut-sm';

		}

		else if ((data.height > 500 && data.height < 700) || (data.width > 600 && data.width < 800)) {
			// this.cardClass2 = 'card-tile-sm';
			// this.cardWideClass2 = 'card-wide-tile-md';

			this.cardTileCenterTitleClass = 'grid-card-title-center-md';
			this.cardWideTileCenterTitleClass = 'grid-wide-card-title-center-md';

			this.holeSize = 60;
			this.holeCenterTextClass = 'donut-center-text-sm';
			this.donutClass = 'donut-sm';

		}
		else if (data.height > 700 || data.width > 800) {
			// this.cardClass2 = 'card-tile-md';
			// this.cardWideClass2 = 'card-wide-tile-lg';

			this.cardTileCenterTitleClass = 'grid-card-title-center-md';
			this.cardWideTileCenterTitleClass = 'grid-wide-card-title-center-md';

			this.holeSize = 60;
			this.holeCenterTextClass = 'donut-center-text-sm';
			this.donutClass = 'donut-sm';

		}

		if (data.width < 400) {
			this.headerGridTitle = 'grid-title-sm';
		}
		else if (data.width > 400 && data.width < 600) {
			this.headerGridTitle = 'grid-title-sm';
		}
		else if (data.width > 600 && data.width < 800) {
			this.headerGridTitle = 'grid-title-lg';
		}
		else if (data.width > 800) {
			this.headerGridTitle = 'grid-title-lg';
		}

	}


	startIntervals() {
		this.updateInterval =
			this.dashboardService.determineProperTacticalDashboardUpdateIntervalForWidget(
				this.widgetObject
			);

		if (this.updateInterval === undefined) {
			this.isLoading = false;
			this.displayNoUpdateIntervalMessage = true;
			return;
		} else {
			this.isLoading = true;
			this.displayNoUpdateIntervalMessage = false;
		}
		let intervalUpdate = this.updateInterval.UpdateInterval * 60000;
		if (this.secondsAgoInterval !== undefined) {
			clearInterval(this.secondsAgoInterval);
			this.secondsAgoInterval = undefined;
		}
		this.retrieveData();

		this.secondsAgoInterval = setInterval(() => {
			if (this.lastUpdatedDate !== undefined) {
				this.displayTimeAgoMessage =
					this.dashboardService.calculateTimeAgo(
						this.lastUpdatedDate
					);
				this.widgetObject.displayTimeAgoMessage =
					this.displayTimeAgoMessage;
			}
		}, 1000);
		if (this.updateControllerInterval !== undefined) {
			clearInterval(this.updateControllerInterval);
			this.updateControllerInterval = undefined;
		}
		this.updateControllerInterval = setInterval(() => {
			this.retrieveData();
		}, intervalUpdate);
	}

	destroyIntervals() {
		if (this.updateControllerInterval) {
			clearInterval(this.updateControllerInterval);
			this.updateControllerInterval = undefined;
		}
		if (this.secondsAgoInterval) {
			clearInterval(this.secondsAgoInterval);
			this.secondsAgoInterval = undefined;
			this.displayTimeAgoMessage = undefined;
			this.widgetObject.displayTimeAgoMessage = undefined;
		}
	}

	initialize() {
		if (_.isNil(this.widgetObject.SiteList)) {
			this.isLoading = false;
			return;
		}
		let statement =
			'API.TacticalDashboardsRetrieveAllRecordsForWidgetId @widgetId = ' +
			this.widgetObject.WidgetId +
			', @userId = ' +
			Global.User.currentUser.Id;
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			this.widgetTabsChartConfigurations = data;
			this.widgetTabs.forEach((tab, index) => {
				if (this.widgetTabsChartConfigurations.length > 0) {
					let foundWidgetTabConfiguration =
						this.widgetTabsChartConfigurations.find(
							(configurationrecord) => {
								return (
									configurationrecord.WidgetTabIndex === index
								);
							}
						);
					if (foundWidgetTabConfiguration !== undefined) {
						tab.graphType = Global.tacticalDashboardGraphTypes.find(
							(graphType) =>
								graphType.id ==
								foundWidgetTabConfiguration.ChartTypeId
						);
					}
				}
			});
			this.widgetTabConfigurationsFinishedMapping = true;

			this.selectedTabIndex =
				this.widgetObject.SelectedTabIndex !== undefined
					? this.widgetObject.SelectedTabIndex
					: 0;
			this.selectedMatTabLabel =
				this.widgetTabs[
					this.widgetObject.SelectedTabIndex !== undefined
						? this.widgetObject.SelectedTabIndex
						: 0
				].textLabel;
			this.widgetTabs[
				this.widgetObject.SelectedTabIndex !== undefined
					? this.widgetObject.SelectedTabIndex
					: 0
			].tabHasBeenLoaded = true;

			this.tab = this.widgetTabs[this.selectedTabIndex];

			this.startIntervals();
		});
	}

	showDialogModalPopupGrid(tab) {
		const cuSummaryModal = this.dialog.open(DialogModalParentComponent, {
			width: Global.isMobile ? '95%' : '60%',
			height: Global.isMobile ? '95%' : '80%',
			data: {
				graphId: uuidv4(),
				groupable: tab.groupable,
				groupColumns: tab.groupable === true ? tab.groupColumns : [],
				popupTitle: 'Raw Data: ' + tab.textLabel,
				WidgetName: 'Kendo-Grid',
				tableStructure: tab.tableStructure,
				tableData: tab.tableData,
				canCreateDashboardWidgetFromPopup: false,
			},
			maxWidth: '100vw',
			maxHeight: '100vh',
		});
	}

	retrieveData() {
		this.timeScopeObject =
		this.dashboardService.determineProperTimeScopeForWidget({
			widgetObject: this.widgetObject,
			UTCConfiguration: false,
		});
		if (this.timeScopeObject === undefined) {
			this.displayNoTimeScopeMessage = true;
			this.isLoading = false;
		} else {
			this.displayNoTimeScopeMessage = false;
			if (
				this.widgetTabs[0].tabHasBeenLoaded === true ||
				this.widgetTabs[1].tabHasBeenLoaded === true
			) {
				this.widgetTabs[0].isLoading = this.widgetTabs[1].isLoading =
					true;
				let statement =
					"API.TacticalDashboard_GS_CurrentAircraftDocked @accessToken= '" +
					Global.User.currentUser.ODataAccessToken +
					"'";
				if (!_.isNil(this.widgetObject.SiteList)) {
					statement =
						statement +
						", @siteIdList = '" +
						this.widgetObject.SiteList +
						"'";
				}

				this.dataService
					.SQLActionAsPromise(statement)
					.then((data: any) => {
						if (data.length === 0) {
							this.widgetTabs[0].tableData = data;
							this.widgetTabs[1].tableData = data;
							this.widgetTabs[0].isLoading =
								this.widgetTabs[1].isLoading = false;
						} else {
							data.forEach((asset) => {
								asset.DateOfObservationDateObject = new Date();
								asset.ProperAssetName =
									asset.SiteName +
									' ' +
									asset.GateName +
									' ' +
									asset.AssetName;
								if (asset.AircraftDockedStatus === 0) {
									asset.IsAircraftDocked = false;
									asset.IsAircraftDockedInt = 0;
								} else {
									asset.IsAircraftDocked = true;
									asset.IsAircraftDockedInt = 1;
								}
							});
							this.widgetTabs[0].tableData = data;
							this.widgetTabs[1].tableData = data;
							this.widgetTabs[1].chartData = [
								{
									dataType: 'Aircraft Docked Total',
									total: 0,
									tooltip: [],
								},
								{
									dataType: 'Aircraft Not Docked Total',
									total: 0,
									tooltip: [],
								},
							];

							let notDocked = [];
							let docked = [];
							data.forEach((asset, index) => {
								if (asset.IsAircraftDocked === false) {
									notDocked.push(asset);
								} else if (asset.IsAircraftDocked === true) {
									docked.push(asset);
								}
							});
							docked.forEach((asset, index) => {
								this.widgetTabs[1].chartData[0].total =
									this.widgetTabs[1].chartData[0].total + 1;
								this.widgetTabs[1].chartData[0].tooltip.push(
									asset.ProperAssetName
								);
							});
							notDocked.forEach((asset, index) => {
								this.widgetTabs[1].chartData[1].total =
									this.widgetTabs[1].chartData[1].total + 1;
								this.widgetTabs[1].chartData[1].tooltip.push(
									asset.ProperAssetName
								);
							});

							console.log(this.widgetTabs[1].chartData);
							this.widgetTabs[1].chartDataIsReady = true;


							this.totalAssetLength = data.length;
							this.summaryArray[0] = this.widgetTabs[1].chartData[0].total; // Assets Docked
							this.summaryArray[1] = this.widgetTabs[1].chartData[1].total; // Assets Not docked
							if (this.summaryArray[0] === 0) {
								this.summaryArray[2] = 0.0 // Percent Docked
							} else {
								this.summaryArray[2] = parseFloat(((this.summaryArray[0] / this.totalAssetLength) * 100).toFixed(1)); //Percent Docked
							}
							if (this.summaryArray[1] === 0) {
								this.summaryArray[3] = 0.0 // Percent Not Docked
							} else {
								this.summaryArray[3] = parseFloat(((this.summaryArray[1] / this.totalAssetLength) * 100).toFixed(1)); //Percent Not Docked
							}


							this.ConditionalColorSummaryTiles();


							this.percentCalculated = true;
							this.widgetTabs[0].isLoading =
								this.widgetTabs[1].isLoading = false;
						}
					});
			}

			if (this.widgetTabs[2].tabHasBeenLoaded === true) {
				this.widgetTabs[2].isLoading = true;
				let specificMomentInTime =
					"API.TacticalDashboard_GS_AircraftDocked_ForSpecificMomentInTime @accessToken='" +
					Global.User.currentUser.ODataAccessToken +
					"'";
				if (!_.isNil(this.widgetObject.SiteList)) {
					specificMomentInTime =
						specificMomentInTime +
						", @siteIdList = '" +
						this.widgetObject.SiteList +
						"'";
				}

				specificMomentInTime =
					specificMomentInTime +
					', @startDateTimeMS=' +
					this.timeScopeObject.queryStartDate +
					', @endDateTimeMS= ' +
					this.timeScopeObject.queryEndDate;

				this.dataService
					.SQLActionAsPromise(specificMomentInTime)
					.then((data: any) => {
						if (data.length === 0) {
							this.widgetTabs[2].tableData = data;
							this.widgetTabs[2].chartData = data;
							this.widgetTabs[2].isLoading = false;
						} else {
							console.log(data);
							data.forEach((record) => {
								record.ProperAssetName =
									record.SiteName +
									' ' +
									record.GateName +
									' ' +
									record.AssetName;
							});
							this.widgetTabs[2].chartData = _.uniqBy(
								data,
								(e: any) => {
									return e.DateOfObservation;
								}
							);
							this.widgetTabs[2].chartDataIsReady = true;
							this.widgetTabs[2].tableData = data;
							this.widgetTabs[2].isLoading = false;
						}
					});
			}

			if (
				this.widgetTabs[0].tabHasBeenLoaded === true ||
				this.widgetTabs[3].tabHasBeenLoaded === true ||
				this.widgetTabs[4].tabHasBeenLoaded === true
			) {
				this.widgetTabs[0].isLoading =
					this.widgetTabs[3].isLoading =
					this.widgetTabs[4].isLoading =
						true;
				let aircraftDockedDuration =
					'API.TacticalDashboard_GS_AircraftDocked_Duration ' +
					'@startDateTimeMS=' +
					this.timeScopeObject.queryStartDate +
					', @endDateTimeMS= ' +
					this.timeScopeObject.queryEndDate +
					" , @accessToken='" +
					Global.User.currentUser.ODataAccessToken +
					"'";
				if (!_.isNil(this.widgetObject.SiteList)) {
					aircraftDockedDuration =
						aircraftDockedDuration +
						", @siteIdList = '" +
						this.widgetObject.SiteList +
						"'";
				}

				this.dataService
					.SQLActionAsPromise(aircraftDockedDuration)
					.then((data: any) => {
						if (data.length === 0) {
							this.widgetTabs[3].tableData = data;
							this.widgetTabs[3].chartData = data;
							this.widgetTabs[3].isLoading = false;
							this.widgetTabs[4].tableData = data;
							this.widgetTabs[4].chartData = data;
							this.widgetTabs[4].isLoading = false;
						} else {
							console.log(data);
							data.forEach((record) => {
								record.DateOfObservationDateObject = new Date(
									record.DateOfObservation
								);
								record.ProperAssetName =
									record.SiteName +
									' ' +
									record.GateName +
									' PBB';
								if (record.AircraftDockedCount == null)
									record.AircraftDockedCount = 0;
								if (record.AircraftDockedMinutes == null)
									record.AircraftDockedMinutes = 0;
							});
							this.widgetTabs[3].tableData = data;
							this.widgetTabs[3].chartData = [];
							this.widgetTabs[3].chartData = groupBy(data, [
								{ field: 'ProperAssetName' },
							]) as GroupResult[];
							if (this.widgetTabs[3].chartData.length > 0) {
								this.widgetTabs[3].chartData.push({
									value: 'Toggle All Series',
								});
							}

							this.widgetTabs[3].chartDataIsReady = true;
							this.widgetTabs[3].isLoading = false;

							this.widgetTabs[4].tableData = data;
							this.widgetTabs[4].chartData = [];
							this.widgetTabs[4].chartData = groupBy(data, [
								{ field: 'ProperAssetName' },
							]) as GroupResult[];
							if (this.widgetTabs[4].chartData.length > 0) {
								this.widgetTabs[4].chartData.push({
									value: 'Toggle All Series',
								});
							}

							let sortByDockCount = data;
							sortByDockCount.sort(
								(a, b) =>
									b.AircraftDockedCount -
									a.AircraftDockedCount
							);
							if (sortByDockCount.length > 0) {
								this.summaryArray1[0] =
									sortByDockCount[0].AircraftDockedCount; //Highest AircraftDockedCount
								this.summaryArray1[1] =
									sortByDockCount[0].DateOfObservation; //Highest AircraftDockedCount DateOfObservation
								this.summaryArray1[2] =
									sortByDockCount[0].ProperAssetName; //Highest AircraftDockedCount Asset Name
							}

							let sortByDockDuration = data;
							sortByDockDuration.sort(
								(a, b) =>
									b.AircraftDockedMinutes -
									a.AircraftDockedMinutes
							);
							if (sortByDockDuration.length > 0) {
								this.summaryArray1[3] =
									sortByDockDuration[0].AircraftDockedMinutes; //Highest AircraftDockedMinutes
								this.summaryArray1[4] =
									sortByDockDuration[0].DateOfObservation; //Highest AircraftDockedMinutes DateOfObservation
								this.summaryArray1[5] =
									sortByDockDuration[0].ProperAssetName; //Highest AircraftDockedMinutes Asset Name
							}
							let sortByDockByDay = groupBy(data, [
								{ field: 'DateOfObservation' },
							]) as any[];
							sortByDockByDay.forEach((asset: any) => {
								asset.TotalDocksCount = 0;
								asset.items.forEach((dock) => {
									asset.TotalDocksCount =
										asset.TotalDocksCount +
										dock.AircraftDockedCount;
								});
							});
							sortByDockByDay.sort(
								(a, b) =>
									new Date(b.value).getTime() -
									new Date(a.value).getTime()
							);
							this.createSparkLineChart(sortByDockByDay); //Sparkline Data
							this.widgetTabs[4].chartDataIsReady = true;
							this.widgetTabs[0].isLoading =
								this.widgetTabs[4].isLoading = false;
							// this.totalMinutesOnForDayChartDataIsReady = true;
						}
					});
			}

			if (this.widgetTabs[5].tabHasBeenLoaded === true) {
				this.widgetTabs[5].isLoading = true;
				let aircraftDockedTodayDetail =
					"API.TacticalDashboard_GS_AircraftDocked_Detail_Today @accessToken='" +
					Global.User.currentUser.ODataAccessToken +
					"'";
				if (!_.isNil(this.widgetObject.SiteList)) {
					aircraftDockedTodayDetail =
						aircraftDockedTodayDetail +
						", @siteIdList = '" +
						this.widgetObject.SiteList +
						"'";
				}
				this.dataService
					.SQLActionAsPromise(aircraftDockedTodayDetail)
					.then((data: any) => {
						if (data.length === 0) {
							this.widgetTabs[5].tableData = data;
							this.widgetTabs[5].chartData = data;
							this.widgetTabs[5].isLoading = false;
						} else {
							console.log("Docks For Today", data);
							data.forEach((record) => {
								record.ProperAssetName =
									record.SiteName +
									' ' +
									record.GateName +
									' ' +
									record.AssetName;

								record.DurationInMinutes = (
									record.DurationInSeconds / 60
								).toFixed(2);
								record.count = 1;
							});
							let assetAggregated = groupBy(data, [
								{ field: 'ProperAssetName' },
							]) as any[];
							assetAggregated.forEach((group) => {
								group.inUseLength = group.items.length;
							});

							this.totalDocksForToday = data?.length;
							this.widgetTabs[5].chartData = assetAggregated;
							this.widgetTabs[5].chartDataIsReady = true;
							this.widgetTabs[5].tableData = data;
							this.widgetTabs[5].isLoading = false;
						}
					});
			}

			this.lastUpdatedDate = new Date();

			this.isLoading = false;

			var bounds = this.elem.nativeElement.getBoundingClientRect();
			this.cardResize(bounds);
		}
	}


	private buildNavigationOptionsArray() {
		var service = this;

		var navigationWidth = Global.isMobile ? "105px" : "115px";

		service.options = [
			{
				id: 1,
				width: navigationWidth,
				name: "Navigation",
				children: [
					{
						id: 10,
						width: navigationWidth,
						name: "Summary",
						action: () => { service.navigateToTab(0, "Summary");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Current Docked",
						action: () => { service.navigateToTab(1, "Current Docked Assets");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Docked by Day",
						action: () => { service.navigateToTab(2, "Assets Docked Current Time");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Minutes Docked",
						action: () => { service.navigateToTab(3, "Total Minutes Docked For Day");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Dock Count",
						action: () => { service.navigateToTab(4, "Total Times Docked For Day");  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Docks Today",
						action: () => { service.navigateToTab(5, "Docks For Today Detail");  this.navigationOpened=false; }
					},


					// {
					// 	id: 11,
					// 	width: navigationWidth,
					// 	name: "Raw Data",
					// 	children: [
					// 		{
					// 			id: 12,
					// 			width: navigationWidth,
					// 			name: "Current Docked",
					// 			action: () => { service.showDialogModalPopupGrid(service.widgetTabs[1]);  this.navigationOpened=false; }
					// 		},
					// 		{
					// 			id: 12,
					// 			width: navigationWidth,
					// 			name: "Docked by Day",
					// 			action: () => { service.showDialogModalPopupGrid(service.widgetTabs[2]);  this.navigationOpened=false; }
					// 		},
					// 		{
					// 			id: 12,
					// 			width: navigationWidth,
					// 			name: "Minutes Docked",
					// 			action: () => { service.showDialogModalPopupGrid(service.widgetTabs[3]);  this.navigationOpened=false; }
					// 		},
					// 		{
					// 			id: 12,
					// 			width: navigationWidth,
					// 			name: "Dock Count",
					// 			action: () => { service.showDialogModalPopupGrid(service.widgetTabs[4]);  this.navigationOpened=false; }
					// 		},
					// 		{
					// 			id: 12,
					// 			width: navigationWidth,
					// 			name: "Docks Today",
					// 			action: () => { service.showDialogModalPopupGrid(service.widgetTabs[5]);  this.navigationOpened=false; }
					// 		},

					// 	],


					// }

				],
				root: true,
				opened: false
			},
			{
				id: 2,
				width: navigationWidth,
				name: "Settings",
				children: [
					{
						id: 10,
						width: navigationWidth,
						name: "Site",
						action: () => { service.widgetObject.editWidgetSettings(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Edit Name",
						action: () => { service.widgetObject.editWidgetName(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Reload",
						action: () => { service.dashboardService._reloadedWidget.next(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Delete",
						action: () => { service.widgetObject.deleteFunction();  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Timescope",
						action: () => { service.widgetObject.editTimeScopeSettings(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Interval",
						action: () => { service.widgetObject.editIntervalSettings(service.widgetObject);  this.navigationOpened=false; }
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Legend",
						action: () => { service.toggleLegendHidden(service.tab, service.selectedTabIndex);  this.navigationOpened=false; }
					},

					{
						id: 23,
						width: navigationWidth,		// id has to match id in toggle method below
						name: service.widgetObject.RememberSelectedTabIndex == 0 ? "Remember Nav" : "Forget Nav",
						action: () => { service.widgetObject.invertRememberingOfSelectedTabIndex(service.widgetObject); this.toggleRememberingOfSelectedTabIndexName(); this.navigationOpened=false; }
					},

				],
				root: true,
				opened: false
			}
		];

		let optionsDesktop = [
			{
				id: 22,		// id has to match id in toggle method below
				width: navigationWidth,
				name: service.widgetObject.dragEnabled ? "Disable Drag" : "Allow Drag",
				action: () => { service.widgetObject.invertDraggableSetting(service.widgetObject); this.toggleDragName(); this.navigationOpened=false;}
			},
			{
				id: 21,		// id has to match id in toggle method below
				width: navigationWidth,
				name: service.widgetObject.resizeEnabled ? "Disable Resize" : "Allow Resize",
				action: () => { service.widgetObject.invertResizeSetting(service.widgetObject); this.toggleResizeName(); this.navigationOpened=false;}
			},
		];

		if(Global.isMobile == false) {
			service.options[1].children = service.options[1].children.concat(optionsDesktop);
		}

		service.optionsSettingsOnly = service.options.where((item:any) => { return item.id == 2 }).toArray(); //- Settings tab only when no Site selected.

		console.log("options built", service.options);

	}


	public editWidgetSettings() {
		this.widgetObject.editWidgetSettings(this.widgetObject)
	}

	public refreshWidget() {
		this.dashboardService._reloadedWidget.next(this.widgetObject);
	}

	public changeWidgetInterval() {
		this.widgetObject.editIntervalSettings(this.widgetObject);
	}

	public changeWidgetTimescope() {
		this.widgetObject.editTimeScopeSettings(this.widgetObject);
	}


	private toggleRememberingOfSelectedTabIndexName() {
		//console.log("options:", this.options)

		var settings = this.options.where((item:any) => { return item.id == 2 }).firstOrDefault();
		var rememberTabSetting = settings.children.where((item:any) => { return item.id == 23 }).firstOrDefault();

		rememberTabSetting.name = this.widgetObject.RememberSelectedTabIndex == 0 ? "Remember Nav" : "Forget Nav";

	}

	private toggleDragName() {
		//console.log("options:", this.options)

		var settings = this.options.where((item:any) => { return item.id == 2 }).firstOrDefault();
		var dragSetting = settings.children.where((item:any) => { return item.id == 22 }).firstOrDefault();

		dragSetting.name = this.widgetObject.dragEnabled ? "Disable Drag" : "Enable Drag";

	}

	private toggleResizeName() {
		//console.log("options:", this.options)

		var settings = this.options.where((item:any) => { return item.id == 2 }).firstOrDefault();
		var resizeSetting = settings.children.where((item:any) => { return item.id == 21 }).firstOrDefault();

		resizeSetting.name = this.widgetObject.resizeEnabled ? "Disable Resize" : "Allow Resize";

	}

	public checkNavigation(opened: any) {
		Global.User.DebugMode && console.log("checkNavigation opened = %O", opened);
		this.navigationOpened = opened;
		this.isLoading = false;
	}

	public checkSelectedItem(selected: any) {
		Global.User.DebugMode && console.log("checkSelectedItem invoked. selected = %O", selected);
	}

	public openCloseNavigation() {
		Global.User.DebugMode && console.log("openCloseNavigation invoked. navigationOpened current status: = " + this.navigationOpened);
		this.navigationOpened = !this.navigationOpened; //--whatever it was set to, set it to the opposite.
		Global.User.DebugMode && console.log("openCloseNavigation invoked. navigationOpened changed status: = " + this.navigationOpened);
	}


	createSparkLineChart(chartData: any) {
		this.sparklineData = [
			{
				field: 'y',
				width: 0.75,
				color: 'limegreen',
				data: chartData.map((a) => ({
					y: a.TotalDocksCount,
					dateOfObs: a.value,
				})),
			},
		];
	}

	toggleLegendHidden(tab, index) {
		tab.legendVisible = !tab.legendVisible;
		let foundSetting = this.WidgetTabSettings.find((widgetTabSetting) => {
			return widgetTabSetting.TabIndex === index;
		});

		let statement =
			'API.EditWidgetTabSettings @WidgetId = ' +
			this.widgetObject.WidgetId +
			', @TabIndex = ' +
			index +
			', @IsHidden = ' +
			!tab.legendVisible;
		if (!_.isNil(foundSetting)) {
			statement = statement + ', @Id = ' + foundSetting.Id;
		}
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			this.WidgetTabSettings = data;
			this.WidgetTabSettings.forEach((tabSetting) => {
				this.widgetTabs[tabSetting.TabIndex].legendVisible =
					!tabSetting.IsLegendHidden;
			});
		});
	}

	animationRedraw() {
		// if (this.selectedMatTabLabel === "Map") {
		// 	this.loadCorrectTabData(true)
		// }
	}

	navigateToTab(index, textLabel)  {
		this.tab = this.widgetTabs[index];
		if (this.tab.graphType != undefined && this.tab.graphType.name == undefined)
			this.tab.graphType = Global.tacticalDashboardGraphTypes.find((graphType) => graphType.name == this.tab.graphType);


		clearTimeout(this.timeoutToExpire);
		this.selectedMatTabLabel = textLabel;
		this.selectedTabIndex = index;
		if (this.widgetTabs[this.selectedTabIndex].tabHasBeenLoaded === false) {
			this.widgetTabs[this.selectedTabIndex].tabHasBeenLoaded = true;
			this.destroyIntervals();
			this.startIntervals();
		}
		this.timeoutToExpire = setTimeout(() => {
			this.widgetObject.SelectedTabIndex = this.selectedTabIndex;
			if (this.widgetObject.RememberSelectedTabIndex) {
				let returnedWidgetAsArray =
					this.dataService.updateTacticalDashboardWidgetRecord(
						this.widgetObject
					);
			}

		}, 5000);
	}


	tabHasChanged(event) {
		clearTimeout(this.timeoutToExpire);
		this.selectedMatTabLabel = event.tab.textLabel;
		this.selectedTabIndex = event.index;
		if (this.widgetTabs[this.selectedTabIndex].tabHasBeenLoaded === false) {
			this.widgetTabs[this.selectedTabIndex].tabHasBeenLoaded = true;
			this.destroyIntervals();
			this.startIntervals();
		}
		this.timeoutToExpire = setTimeout(() => {
			this.widgetObject.SelectedTabIndex = this.selectedTabIndex;
			if (this.widgetObject.RememberSelectedTabIndex) {
				let returnedWidgetAsArray =
					this.dataService.updateTacticalDashboardWidgetRecord(
						this.widgetObject
					);
			}

		}, 5000);
	}

	onResized(event: ResizeResult): void {
		this.parentContainerSize = event.height;
		if (this.childOverviewGauge !== undefined) {
			this.childOverviewGauge.reFlow();
		}
	}

	ngOnDestroy() {
		this.destroyIntervals();
	}

	changeGraphType(event) {
		let i = this.selectedTabIndex;
		this.widgetTabs[i].graphType = event;
		let existingRecord = this.widgetTabsChartConfigurations.find(
			(record) => {
				return record.WidgetTabIndex === i;
			}
		);

		let statement =
			'API.TacticalDashboardAddOrUpdateWidgetChartIndexRecord @widgetId = ' +
			this.widgetObject.WidgetId +
			', @userId = ' +
			Global.User.currentUser.Id +
			', @chartTypeId = ' +
			event.id +
			', @widgetTabIndex = ' +
			i;
		if (existingRecord !== undefined) {
			statement = statement + ', @id = ' + existingRecord.Id;
		}
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			this.widgetTabsChartConfigurations = data;
			this.utilityService.showToastMessageShared({
				type: 'success',
				message: 'Chart type changed to ' + event.properName,
			});
			//   this.toastr.success("Chart type changed to " + event.properName);
		});
	}

	showChartAsPopup(tab) {
		var popupData;
		var field;
		var categoryField;
		var XAxisTitle;
		var canIterate;

		if (tab.textLabel === 'Current Docked Assets') {
			field = 'total';
			categoryField = 'dataType';
			canIterate = false;
		}
		else if (tab.textLabel === 'Assets Docked Current Time') {
			field = 'CountOfAircraftDockedOn';
			categoryField = 'DateOfObservationDateObject';
			XAxisTitle = 'Total Assets Docked at Current Time';
			canIterate = false;
		}
		else if (tab.textLabel === 'Total Minutes Docked For Day') {
			field = 'AircraftDockedMinutes';
			categoryField = 'DateOfObservationDateObject';
			XAxisTitle = 'Total Minutes Docked For Day';
			canIterate = true;
		}
		else if (tab.textLabel === 'Total Times Docked For Day') {
			field = 'AircraftDockedCount';
			categoryField = 'DateOfObservationDateObject';
			XAxisTitle = 'Total Times Docked For Day';
			canIterate = true;
		}
		else if (tab.textLabel === 'Docks For Today Detail') {
			field = 'inUseLength';
			categoryField = 'value';
			canIterate = false;
		}


		popupData = {
			categoryField: categoryField,
			field: field,
			popupTitle: 'Raw Chart: ' + tab.textLabel,
			titleXAxis: XAxisTitle,
			chartTitle: tab.textLabel,
			WidgetName: 'Kendo-Chart',
			chartData: tab.chartData,
			canCreateDashboardWidgetFromPopup: false,
			graphType: tab.graphType?.name,
			legendVisible: tab.legendVisible,
			canIterate: canIterate,
		}

		const cuSummaryModal = this.dialog.open(DialogModalParentComponent, {
			width: Global.isMobile ? '90%' : '90%',
			height: Global.isMobile ? '90%' : '90%',
			data: popupData,
			maxWidth: '100vw',
			maxHeight: '100vh',
		});
	}

	public toggleLegendChange(event: any) {
		this.toggleLegendHidden(this.tab, this.selectedTabIndex);
	}

	CreateTheme(theme: string) {
		if (theme === 'light') {
			this.theme = 'light';
			this.chartBackgroundColor = 'white';
			this.chartLabelColors = 'grey';
			this.gridLineColor = 'grey';
			this.axisItemColor = 'grey';
		} else {
			this.theme = 'dark';
			this.chartBackgroundColor = '#27304C';
			this.chartLabelColors = 'white';
			this.gridLineColor = 'white';
			this.axisItemColor = 'white';
		}
	}

	public toggleSeries(item: any): void {
		let i = item.series.index;

		this.series._results[i].toggleVisibility();
	}

	public toggleAllSeries(e) {
		if (e.text === 'Toggle All Series') {
			this.series._results.forEach((series, index) => {
				this.series._results[index].toggleVisibility();
			});
		}
	}

	initializeTour(): void {
		this.tourService.initialize([
			{
                anchorId: this.widgetObject.WidgetId + '-1',
                content: 'Here you can click on the Site, Update Interval and Timescope areas to change the settings for this widget',
                title: 'Settings',
            },
            {
                anchorId:  this.widgetObject.WidgetId + '-2',
                content: 'Navigate to different tabs & Widget Settings',
                title: 'Navigation',
            },
            {
                anchorId:  this.widgetObject.WidgetId + '-3',
                content: 'Click on various tiles for more information',
                title: 'Summary Info',
            },
		]);

		this.tourService.start();
	}

}

